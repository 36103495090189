import actionTypes from './actionTypes';
import { apiMainQuery } from '../../settings/apollo/api/main/query';
import { apiMainMutation } from '../../settings/apollo/api/main/mutation';
import {
  updateCustomerPersonalInfoMutation,
  verifyCustomerPhoneVerificationCodeMutation,
  uploadFileMutation,
  sendCustomerPhoneVerificationCodeQuery,
  customerQuery,
  sendVerifyAccountLinkMutation,
  pinToAddressVerificationMutation,
  verifyAccountFromLinkMutation,
  saveEftInfoMutation,
  cusEftInfoQuery,
  cusEmailProviderContactsEmailsQuery,
  cusHasEftAccountQuery,
  cusEftInfoListQuery,
  cusCardsQuery,
  saveCusCardMutation,
  updateCusCardMutation,
  deleteCusCardMutation,
  sendCustomerEmailVerificationCodeQuery,
  verifyCustomerEmailVerificationCodeMutation,
  isCustomerEmailAvailableQuery,
  isBrazeEnabledQuery,
  cusEftCurrencyListQuery,
  checkTabapayStatusQuery
} from './graphql.graphql';
import { Analytics, ACTIONS } from '../../settings/analytics';
import { createHash, removeMask } from '../../shared/utility'
import { store } from '../store';

export const updateCustomer = (personalInformation, cus_info, isVerification) => {
  return dispatch => {
    return apiMainMutation(updateCustomerPersonalInfoMutation, {
      personalInformation,
      isVerification
    }).then(result => {
      const { success } = result;
      if (success) {
        if (cus_info) {
          Analytics.track(ACTIONS.SIGNUP_COMPLETED, { personalInformation: { ...personalInformation, ...cus_info } });
        }
        dispatch({ type: actionTypes.CUSTOMER_UPDATED, payload: personalInformation })
      }
      return result;
    });
  };
};

export const updateCustomerEmployment = employment => {
  return dispatch => {
    return apiMainMutation(updateCustomerPersonalInfoMutation, { employment }).then(result => {
      const { success } = result;
      if (success) dispatch({ type: actionTypes.CUSTOMER_EMPLOYMENT_UPDATED, payload: employment });
      return result;
    });
  };
};

export const verifyCustomerPhoneVerificationCode = (code, cus_unique_id, cus_phone1) => {
  return apiMainMutation(verifyCustomerPhoneVerificationCodeMutation, { code, cus_unique_id, cus_phone1 });
};

export const sendVerifyAccountLink = (type = 'EMAIL') => {
  return apiMainMutation(sendVerifyAccountLinkMutation, { type });
};

export const uploadFile = (container, type, file, fileChunks) => {
  return apiMainMutation(uploadFileMutation, { container, type, file, fileChunks });
};

export const pinToAddressVerification = (type, pin_value) => {
  return apiMainMutation(pinToAddressVerificationMutation, { type, pin_value });
};

export const verifyAccountFromLink = token => {
  return apiMainMutation(verifyAccountFromLinkMutation, { token });
};

export const saveEftInfo = eft => {
  return apiMainMutation(saveEftInfoMutation, { eft });
};

export const sendCustomerPhoneVerificationCode = (
  cus_phone1,
  { isFromResend, cus_email, cus_unique_id, retry_attempt, is_business = false } = {}
) => {
  return apiMainQuery(sendCustomerPhoneVerificationCodeQuery, { cus_phone1, retry_attempt, cus_unique_id }).then((result) => {
    const { success } = result;
    if (success) {
      if (!isFromResend) {
        Analytics.track(ACTIONS.SIGNUP_PHONE_NUMBER_SUBMITTED, {
          personalInformation: { cus_phone1, cus_email, cus_unique_id, is_business: !!is_business },
        });
      }
    }
    return result;
  });
};

export const customer = () => {
  return apiMainQuery(customerQuery);
};

export const setCusCompliance = compliance => ({
  type: actionTypes.SET_COMPLIANCE,
  payload: compliance
});

export const setCustomerPhone = phoneNumber => ({
  type: actionTypes.CUSTOMER_PHONE_UPDATED,
  payload: phoneNumber
});

export const setCustomerUpdate = (update) => {
  return (dispatch) => {
    return dispatch({
      type: actionTypes.CUSTOMER_UPDATED,
      payload: update,
    });
  };
};

export const cusEftInfo = () => {
  return apiMainQuery(cusEftInfoQuery);
};

export const cusEftInfoList = (account_currency, business_unique_id, is_fail_for_testing, type) =>{
  return apiMainQuery(cusEftInfoListQuery, { account_currency, business_unique_id, is_fail_for_testing,type });
} 

export const cusEmailProviderContactsEmails = (type, token, redirectUri) => {
  return apiMainQuery(cusEmailProviderContactsEmailsQuery, { type, token, redirectUri });
};

export const cusHasEftAccount = (account_currency, business_unique_id,manual=false) =>{
  return apiMainQuery(cusHasEftAccountQuery, { account_currency, business_unique_id,manual });
} 

export const fetchCusCards = async (business_unique_id) => await apiMainQuery(cusCardsQuery, { business_unique_id } );

export const checkTabapayStatus = async () => await apiMainQuery(checkTabapayStatusQuery, { tran_source: 'WEB' });
export const saveCusCard = async (cardDetails) => {
  cardDetails.expiry_date = cardDetails.expiry_date.replace('/', '');
  cardDetails.card_number = removeMask(cardDetails.card_number);
  const cardHash = createHash(JSON.stringify(cardDetails))

  const res =  await apiMainMutation(saveCusCardMutation, { cardHash });
  return res;
}

export const updateCusCard = async (id, cardDetails) => {
  cardDetails.expiry_date = cardDetails.expiry_date.replace('/', '');
  delete cardDetails.card_number;
  const cardHash = createHash(JSON.stringify(cardDetails));

  const res =  await apiMainMutation(updateCusCardMutation, { id, cardHash });
  return res;
}

export const deleteCusCard = async (id, business_unique_id) => await apiMainMutation(deleteCusCardMutation, {id, business_unique_id});

export const setPromoRedirect = (url) => ({
  type: actionTypes.PROMO_REDIRECT,
  payload: url
})

export const sendCustomerEmailVerificationCode = (cus_email) => {
  return apiMainQuery(sendCustomerEmailVerificationCodeQuery, { cus_email: cus_email });
};

export const verifyCustomerEmailVerificationCode = code => {
  return apiMainMutation(verifyCustomerEmailVerificationCodeMutation, { code });
};

export const cusEftCurrencyList = async () => await apiMainQuery(cusEftCurrencyListQuery, {});

export const isCustomerEmailAvailable = async (cus_email) => await apiMainQuery(isCustomerEmailAvailableQuery, { cus_email });

export const brazeSessionStart = async () => {
  const result = await apiMainQuery(isBrazeEnabledQuery, {});
  store.dispatch({ type: actionTypes.IS_BRAZE_ENABLED, payload: result?.data?.isBrazeEnabled });
  return result;
}