import actionTypes from './actionTypes'

const initState = {
    compliance: {},
    cus_unique_id: null,
    is_braze_enabled: false,
    promo_redirect: '/send-money'
}

const customerReducers = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return { ...state, ...action.payload.personalInformation }
        case actionTypes.SIGN_UP_SUCCESS:
            return { ...state, ...action.payload.personalInformation }
        case actionTypes.CUSTOMER_UPDATED:
            return { ...state, ...action.payload } 
        case actionTypes.CUSTOMER_PHONE_UPDATED:
            return { ...state, cus_phone1: action.payload } 
        case actionTypes.CUSTOMER_EMPLOYMENT_UPDATED:
            return { ...state, ...action.payload }
        case actionTypes.SET_COMPLIANCE:
            return { ...state, compliance: action.payload }
        case actionTypes.PROMO_REDIRECT: 
            return {...state, promo_redirect: action.payload }
        case actionTypes.IS_BRAZE_ENABLED: 
            return {...state, is_braze_enabled: action.payload }
        case actionTypes.CLEAR_STATE:
            return { ...initState }
        default: return state
    }
}

export default customerReducers;